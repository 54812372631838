<template>
  <div>
          <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">更换手机号码</div>
    </div>
  </nav-bar-back>
   <div class="content">
       <div class="header">
           <div class="headerLeft">
                <div class="selected">
                    1
                </div>
                <p >验证当前手机</p>
               
           </div>
            <div class="headerCenter">
                <div v-if="status"> <img :src="full" /> </div>

                <div v-else> <img :src="halfFull" /> </div>
           </div>
            <div class="headerLeft">

                <div :class="status ?'selected':'unselected'" >
                    2
                </div>
                <p >绑定新手机</p>
              
           </div>
           <!-- <img src="~assets/images/common/logo_huanChuang.png"> -->
       </div>

       <div class="inputBody">
         <div class="input">
  <input v-if="!status" type="text" v-model="phone"  placeholder="请输入原手机号码" />
  <input v-else type="text"  v-model="phone" placeholder="请输入新手机号码" />
      
      
         </div>

            <div class="yanzheng">
            <div class="input" style="width:70%; border-radius: 5px 0px 0px 5px" >
           <input type="text" v-model="capture" placeholder="请输入验证码" />
        
         </div>
           <div  v-show="show" @click="getCode"  class="right">获取验证码</div>
             <div v-show="!show"  class="right code"> <span>{{count }}s</span> </div>
           </div>
          
         </div>
       </div>

       <div class="bt">
           
    <van-button  v-if="!status" class="login" @click="commit">下一步</van-button>
    <van-button  v-else  class="login" @click="commit">确定</van-button>
</div>

   </div>

 

  
</template>

<script>
import NavBarBack from 'common/navbar/NavBarBack'
import {verificationMobile,mobile} from "network/identify";
import {getCapture} from "network/login";

// verificationMobile
import { Cell, CellGroup,Field,Button,DatetimePicker,Popup ,Picker, Toast} from 'vant';
export default {
    data () {
        return {
          id:'',
          status:false,
           count:'',
         show:true,
         timer:'',
         phone:'',
         capture:'',
         full:require('@/assets/images/audit/fullBar.png'),
         halfFull:require('@/assets/images/audit/halfBar.png'),
         oldPhone:''
        }
    },
    created () {
  

            this.phone= localStorage.getItem('phone')
        // this.$route('')
       
    },
   components: {
         NavBarBack,
         [Field.name]:Field,
         [CellGroup.name]:CellGroup,
         [Button.name]:Button
       
   },
   
   methods: {
         getCode(){
         if(!this.phone){
             Toast.fail('手机号码不能为空')
             return
         }
         getCapture(this.phone).then(res=>{
             
            this.verification_key=res.key
            if( this.verification_key){
          const TIME_COUNT = 60;
     if (!this.timer) {
       this.count = TIME_COUNT;
       this.show = false;
       this.timer = setInterval(() => {
       if (this.count > 0 && this.count <= TIME_COUNT) {
         this.count--;
        } else {
         this.show = true;
         clearInterval(this.timer);
         this.timer = null;
        }
       }, 1000)
     }  
            }else{
                Toast.fail('手机号码有误')
            }

     
         })

     },
  commit(){
         if(!this.phone){
             Toast.fail("请输入手机号码")
             return 
         }
         if(!this.capture){
             Toast.fail("验证码不能为空")
             return
         }
        //  mobile, verification_key, verification_code
        if(this.status){
          mobile(this.phone,this.verification_key,this.capture).then(res=>{
             if(res=='更改成功'){
                //  this.$router.push('/')
                 Toast.success('更换成功')
             }else{
                  Toast.fail('修改失败')
                //  Toast.fail(res.errors.mobile[0])
             }
          this.show = true;
         clearInterval(this.timer);
         this.timer = null;
         console.log('resw',res)
          })
        }else{
               verificationMobile(this.phone,this.verification_key,this.capture).then(res=>{
            if(res=='successful'){
                this.status=true
                this.phone='',
                this.capture=''
                 this.show = true;
         clearInterval(this.timer);
         this.timer = null;
            }       
         })
        }
     
      
  }
       
   },
 

}
</script>

<style scoped>
.input input{
    /* background-color: aqua; */
    width: 100%;
}
.code{
     color:rgb(219, 76, 10)  !important;
     /* font-size: 15px !important; */
     /* line-height: 49px; */
      text-align: center;

}

.code span{
    font-size: 18px;
    /* margin-right: 3px; */
    font-weight: 400;
}
.unselected{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 42px;
    width: 42px;
background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    background-image: url("~assets/images/audit/unselect.png");
    color: #333;
    
    /* background-position:co; */
}
.headerCenter img{
    width: 130px;
}
.headerCenter{
    margin-top: 10px
}
.headerLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header p{
    margin-top: 12px;
    font-size: 12px;
}
.selected{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 42px;
    width: 42px;
background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("~assets/images/audit/selected.png");
    color: #fff;
    background-position:center;
}

.header{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  
  /* justify-content: space-around; */
  margin-bottom: 20px;
  margin-top: 20px;
}
.yanzheng{
    display: flex
}
.bt{
    display: flex;
    justify-content: center;
    margin-top: 40px
}
.login{
    width: 90%;
    margin: 0 auto;
    color: #fff;
    /* background-color:  */
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));
    /* background-color: #f00 */
}
.right{
    background-color: rgb(253, 183, 10);
    /* height: 49px; */
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
    color: #333;
    font-size: 15px;
    margin-top: 18px;
    width: 40%;
    border-radius: 0 5px 5px 0
     /* 第一个值为左上角，第二个值为右上角，第三个值为右下角，第四个值为左下角。 */
}
.input{
    margin-top: 18px;
   /* border: 1px solid #ddd; */
   /* padding: 30px */
   display: flex;
   padding-left: 12px;
   background-color: #fff;
   padding-top: 12px;
   padding-bottom: 12px;
   border-radius: 5px;
}
.input img{
    width: 25px;
       height:25px;
    margin-right: 14px
}
 .xieLine {
    margin-right: 2px;
      padding: 0.5px;
      /* background-color: aqua; */
      /* width: 0.8px; */
      height: 25px;
      background-color: #ccc;
      /* Rotate div */
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      /* Internet Explorer */
      -moz-transform: rotate(0deg);
      /* Firefox */
      -webkit-transform: rotate(0deg);
      /* Safari 和 Chrome */
      -o-transform: rotate(0deg);
      /* Opera */
   
  }
input{
    background-color: transparent;
    border: none;
   margin-left:10px 

}
.inputBody{
    width: 90%;
    margin: 0 auto
}
/* input::input-placeholder { text-align: 'center' } */
.inputtype >>> .van-cell__value{
  display: flex
}
.logo img{
    width: 90px;
     height:100px;
}
.logo{
    
    display: flex;
    justify-content:center;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px
}

.item{
    display: flex;
    /* justify-content: space-between */
}
.item :first-child{
  margin-right: 16px
    /* display: flex; */
    /* justify-content: space-between */
}
.commit{
    position: fixed;
    bottom: 0;
    width: 100%;
}

</style>